.main {
  padding: 12px 0;
  display: flex;
  align-items: center;
}

.step {
  background-color: white;
  color:  #2468b3;
  border: 1px solid #2468b3;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-weight: 600;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  margin: 0 6px;
}

.current{
  background-color: #2468b3;
  border: 1px solid #2468b3;
  color: white;
}

.line {
  width: 20px;
  position: relative;
}

.line:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 0.5px solid #7592a2;
  width: 100%;
  transform: translateY(-50%);
}
