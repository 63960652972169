.overlay{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.frame{
  position: relative;
  width: 600px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  margin: 0 12px;
}

.closeButton{
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 2em;
  padding: 9px 12px;
  color: black;
  text-decoration: none;
}

.body{
  padding: 24px;
}