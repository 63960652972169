.main {
  padding-bottom: 148px;
}

.fixContainer{
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  padding: 0 12px;
  max-width: 680px;
  margin: -0 auto;
}

.radioButton {
  padding: 12px 0;
  font-weight: 500;
  border-bottom: 1px solid #ececec;
  margin: 0;
  align-items: baseline;
  cursor: pointer;
  display:block;
}

.radioButton:first-child {
  border-top: 1px solid #ececec;
}

.radioButton > input {
  margin-right: 9px;
  display: block;
}

.notBevoegdThroughMotherMessage{
  padding-left: 36px;
  font-size: 0.9em;
  color: red;
  font-weight: 300;
}