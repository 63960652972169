.wrapper {
  position: fixed;
  left: 0;
  top: 48px;
  right: 0;
  bottom: 0;
  background-color: #f3f3f3;
  overflow: auto;
}

/* @media (max-width: 479px) {
  .wrapper {
    top: 48px;
  }
} */

.bgImage {
  position: absolute;
  z-index: -1;
  top: 0;
  background-image: url(../images/hero2.jpg);
  width: 100%;
  height: 438px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgImageOverlay {
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(9, 9, 121, 0) 20%,
    rgba(9, 9, 121, 0) 80%,
    rgba(255, 255, 255, 1) 100%
  );
}

.main {
  margin: 0 auto;
  max-width: 972px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
}

.main h1 {
  color: black;
  padding: 72px 0;
}

.blockContainer {
  display: flex;
}

.innerBlockContainer {
  display: flex;
}

.innerBlockContainer > div:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.innerBlockContainer > div:nth-child(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.block {
  padding: 24px;
  width: 50%;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bevoegdBlock {
  padding: 24px;
  width: 40%;
  border: 1px solid rgba(219, 219, 219, 0.171);
  border-radius: 6px;
  background-color: #2468b3;
  color: white;
  margin-left: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 48em) {
  .bgImage {
    height: 324px;
  }

}

@media (max-width: 854px) {
  .bevoegdBlock {
    width: 50%;
  }
  .main {
    padding: 12px 12px 0;
  }
  .blockContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .bevoegdBlock {
    margin-top: 24px;
    margin-left: 0;
  }
}

@media (max-width: 534px) {
  .bgImage {
    height: 268px;
  }
  .bevoegdBlock {
    width: 100%;
  }
  .main h1 {
    padding: 32px 0;
  }
  .innerBlockContainer {
    flex-direction: column;
  }
  .blockContainer {
    flex-direction: column;
    align-items: stretch;
  }
  .block {
    width: 100%;
  }
  .innerBlockContainer > div:first-child {
    margin-bottom: 12px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .innerBlockContainer > div:nth-child(2) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.blockContainer button {
  width: 100%;
}

.topBlock {
  display: flex;
  align-items: top;
}

.topBlock img {
  padding: 6px 20px 6px 4px;
}

.headerInBlock {
  font-size: 1.2em;
  font-weight: 600;
  /* padding-bottom: 12px; */
}

.subBlocksContainer {
  display: flex;
}

.subBlock {
  width: 50%;
  padding: 24px;
  background-color: white;
  border-radius: 6px;
  margin: 36px 0;
}

.subBlock:first-child {
  margin-right: 36px;
  width: 50%;
}

.authTypeMain {
  color: black;
  text-decoration: none;
  margin-bottom: -1px;
  padding: 16px 0;
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

@media (max-width: 534px) {
  .subBlocksContainer {
    flex-direction: column;
  }

  .subBlock {
    width: 100% !important;
    margin: 0 0 24px;
  }

  .subBlock:first-child {
    margin: 24px 0;
  }
}

.footer {
  background-color: #282828;
  color: white;
}

.footerContent {
  margin: 0 auto;
  max-width: 972px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
}

.footerContent h2 {
  color: white;
}

.footerLinks {
  padding: 24px;
  
}

.footerLinks > div > a{
  font-size: 0.9em;
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.footerLinks > div:hover {
  text-decoration: underline;
}
