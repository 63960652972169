.inputContainer {
  display: flex;
}

.inputContainer > input {
  flex: 1;
}

.inputText {
  width: 100%;
  padding: 14px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #bebebe;
  /* min-width: 260px; */
}
