.inputText {
  width: 100%;
  padding: 14px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #bebebe;
  /* min-width: 260px; */
}

.error {
  font-size: 0.9em;
  color: red;
  height: 24px;
}
