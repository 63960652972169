.main {
  display: flex;
  align-items: center;
  padding: 12px 0;
  flex-wrap: wrap;  
}

.main > a {
  margin: 12px 0;
}

.main > a:first-child {
  margin: 12px 24px 12px 0;
}
