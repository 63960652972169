.authBlockMain {
  padding-bottom: 36px;
}

.title {
  font-weight: 500;
  padding-top: 12px;
}

.main table {
  width: 100%;
  border-spacing: 0;
}

.main table > thead {
  font-size: 0.9em;
  font-style: italic;
  color: #767676;
}

.main table > tbody > tr {
  cursor: pointer;
}

.main table > tbody > tr:hover {
  background-color: rgb(235, 235, 235);
}

.main table > thead > tr > td {
  padding: 0 12px;
}

.main table > tbody > tr > td {
  padding: 18px 12px;
  border-bottom: 1px solid lightgray;
}

.main table > tbody > tr:first-child > td {
  border-top: 1px solid lightgray;
}

.main table > tbody > tr > td:first-child {
  width: 60%;
}

@media (max-width: 479px) {
  .main table > tbody > tr > td {
    padding: 12px 2px;
  }
  .main table > thead > tr > td {
    padding: 0 2px;
  }
}
