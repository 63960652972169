.main {
  display: flex;
  font-size: 0.9em;
  flex-direction: row;
  line-height: 1.3em;
}

.irma {
  width: 54%;
}

.downloadContainer {
  width: 46%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 648px) {
  .main {
    flex-direction: column;
  }

  .irma {
    width: 100%;
  }

  .downloadContainer {
    width: 100%;
  }
}

.header {
  font-weight: bold;
}

.irmaContainer > div {
  width: 100% !important;
}

.download,
.requestAuthorization {
  border: 4px solid #eae5e2;
  padding: 18px;
  border-radius: 4px;
  margin: 1px;
  min-width: 262px;
  max-width: 400px;
}
@media (max-width: 648px) {
  .download{
    display: none;
  }
}

.requestAuthorization {
  flex:1;
}

.downloadButtonContainer {
  display: flex;

}

.downloadButton {
  width: 48%;
}

.downloadButton:first-child {
  margin: 0 4% 0 0;
}

.downloadButton > img {
  width: 100%;
}

.downloadButton:hover  > img {
  transform: translateY(-1px);
}

.downloadButton:active > img,
.downloadButton:hover > img {
  box-shadow: 0 7px 14px hsla(0, 0%, 74.5%, 0.2), 0 3px 6px hsla(0, 0%, 74.5%, 0.2);
}


