.radioButton {
  padding: 12px 0;
  font-weight: 500;
  border-bottom: 1px solid #ececec;
  margin: 0;
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.radioButton:first-child {
  border-top: 1px solid #ececec;
}

.radioButton > input {
  margin-right: 9px;
  display: block;
}

