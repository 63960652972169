.inputContainer {
  padding-bottom: 24px;
}

.inputLabel{
  padding: 3px;
  font-size: 0.9em;
  opacity: 0.7;
}

.selectOption {
  display: flex;
  justify-content: space-between;
}

.searchTypeContainer {
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.searchTypeContainer > * {
  padding: 0 10px;
}

label {
  margin-bottom: 0 !important;
  margin-top: 3px;
}

/* organization */
.main {
  padding: 12px;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 6px;
}

@media (min-width: 48em) {
  .main {
    padding: 24px;
  }
}

.organizationTitle {
  padding: 0 0 12px;
  font-size: 1.2em;
  font-weight: 500;
  display: flex;
  align-items: center;
}

@media (min-width: 48em) {
  .organizationTitle {
    padding: 0 0 24px;
  }
}

.organizationTitle img {
  height: 38px;
}

@media (min-width: 48em) {
  .organizationTitle img {
    height: 68px;
  }
}

.main table {
  border-spacing: 4px 2px;
}

@media (min-width: 48em) {
  .main table {
    border-spacing: 4px 6px;
  }
}

.main td {
  vertical-align: top;
}

.main td:first-child {
  font-weight: 500;
  padding-right: 6px;
}

@media (min-width: 48em) {
  .main td:first-child {
    padding-right: 24px;
  }
}

.error {
  padding: 12px;
  font-size: 0.9em;
  opacity: 0.5;
  font-style: italic;
}


