.main {
  max-width: 1200px;
  margin: 0 auto;
}

.content {
  max-width: 920px;
  margin: 0 auto;
  padding: 12px;
}
