.inputContainer {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  
  .inputContainer > input {
    flex: 1;
    margin-right: 12px;
    font-weight: bold;
  }
  
  .inputText {
    width: 100%;
    padding: 14px;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #bebebe;
    /* min-width: 260px; */
  }
  