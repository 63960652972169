.main {
  padding-bottom: 148px;
}

.fixContainer {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  padding: 0 12px;
  max-width: 680px;
  margin: -0 auto;
}

.alternativeContainer {
  margin: 12px 0;
}

.alternative {
  padding: 12px;
  margin: 12px 0;
  background-color: lightgray;
}

.warning {
  background-color: #fff7df;
  color: #f5700f;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.2;
  padding: 12px;
  margin: 0 -12px;
  display: flex;
  align-items: center;
}

.infoIcon {
  font-size: 1.4em;
  padding-right: 12px;
  font-weight: bold;
}
