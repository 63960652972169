.irma {
  display: flex;
  font-size: 0.9em;
  flex-direction: column;
}

@media (min-width: 48em) {
  .irma {
    flex-direction: row;
  }
}
