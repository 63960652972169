.authTypeMain {
  margin: 24px 0;
}

.authTypeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 12px;
  background-color: #ededed;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* font-weight: bold; */
  /* cursor: pointer; */
}

/* .expanded .authTypeHeader {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
} */

/* .authTypeHeader:hover {
  background-color: #e7e7e7;
} */

.authTypeBody {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  /* border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; */
}

.authTypeItem {
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: black;

}

.authTypeItem:hover {
  background-color: #f8f8f8;
}

.arrow {
  width: 12px;
  height: 12px;
}

/* .expanded .arrow {
  transform: rotate(180deg);
} */

@media (max-width: 479px) {
  .authTypeHeader {
    margin:0 -12px;
  }
  .authTypeItem {
    padding: 10px 12px;

  }
}
