.authContainer {
  margin: 12px 0;
  padding: 12px 6px 6px;
  background-color: #f1f1f1;
}

.main table {
  width: 100%;
  border-spacing: 0;
  line-height: 1.3em;
}

.main table > thead {
  font-size: 0.9em;
  font-style: italic;
  color: #767676;
}

.main table > thead > tr > td {
  padding: 0 12px;
  font-size: 0.8em;
}

.main table > tbody > tr > td {
  padding: 18px 12px;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
  font-size: 0.9em;
}

.main table > tbody > tr:first-child > td {
  border-top: 1px solid #f1f1f1;
}

@media (max-width: 479px) {
  .authContainer {
    padding: 6px 12px;
    margin: 12px -12px;
  }

  .main table > tbody > tr > td {
    padding: 12px 6px;
  }
  .main table > thead > tr > td {
    padding: 0 2px;
  }
}
