.main {
  visibility: visible;
  width: 300px;
  margin-left: -150px;
  color: #fff;
  text-align: center;
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: 30px;
  padding: 12px;
  background-color: #cc2020;
  border-radius: 6px;
  max-width: 600px;
  font-weight: 500;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.2);
  animation: fadein 0.5s, fadeout 0.5s 3.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
