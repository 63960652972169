.main {
  padding: 0 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 48px;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
  /* padding: 0 10px; */
  line-height: 1.1em;
  max-width: 78%;
}

.title {
  padding-left: 12px;
  font-size: 1.2em;
  font-weight: 100;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  padding: 6px;
  height: 36px;
}

.home {
  padding-right: 6px;
  height: 22px;
}

.homeLabel {
  display: none;
}

.menu {
  padding: 0 12px;
  display: flex;
}

.menu > a {
  padding: 0 8px;
  font-size: 0.9em;
  text-decoration: none;
  color: black;
  margin-bottom: -2px;
  display: flex;
  align-items: center;
}

.menu > a:hover {
  text-decoration: underline;
}

.hamburger {
  display: none;
}

.mobileMenuContainer {
  position: fixed;
  right: 0;
  left: 0;
  top: 48px;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
}

.mobileMenu {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  width: 75%;
  text-align: right;
}

.mobileMenu > a {
  padding: 12px 18px;
  text-decoration: none;
  border-bottom: 1px solid rgb(233, 233, 233);
  font-size: 1.1em;
  text-decoration: none;
  display: flex;
  align-items: center;
}

@media (max-width: 479px) {
  .title,
  .menu {
    display: none;
  }
  .hamburger {
    display: block;
  }
  .logo {
    height: 28px;
    padding: 3px 6px;
  }
  .homeLabel {
    display: block;
  }
}

.usernameContainer {
  display: flex;
  align-items: center;
  text-align: right;
  line-height: 1.1em;
  font-size: 0.9em;
}

.username {
  vertical-align: middle;
}

.person {
  width: 24px;
  opacity: 0.6;
  display: inline-block;
  padding-right: 2px;
  vertical-align: middle;
}

.logoutDesktop {
  padding-left: 12px;
  vertical-align: middle;
  white-space: nowrap;
}

@media (max-width: 479px) {
  .logoutDesktop {
    display: none;
  }
}
