.step {
  display: flex;
  align-items: center;
  line-height: normal;
}

.stepDivider {
  border: 1px solid #2468b3;
  width: 1px;
  height: 15px;
  margin: 3px 0 3px 23px;
}

.stepCount {
  background-color: white;
  color:  #2468b3;
  border: 1px solid #2468b3;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-weight: 600;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  margin: 0 12px;
  flex-shrink: 0;
}
